<template>
  <div class="videoDetailsPage">
    <div class="headBar">
      <div class="leftIcon" @click="$router.go(-1)">
        <img src="@/assets/png/leftblack.png" />
      </div>
      <!-- <div
        class="countdown"
        v-if="
          videoAdv &&
          videoInfo &&
          !(
            videoInfo.listenPermission == 1 &&
            videoInfo.hasPaid &&
            userInfo.isVip
          ) &&
          showVideoAdv
        "
        @click="closeAdv"
      > -->
      <!-- <div
        class="countdown"
        v-if="videoInfo &&
          !(
            videoInfo.listenPermission == 1 &&
            videoInfo.hasPaid &&
            userInfo.isVip
          )
        "
        @click="closeAdv"
      > -->
      <!-- <div v-if="adsTimeLongVideo > 0"> -->
      <!-- <span v-if="isCanClose()">
            {{ adsTimeLongVideo }}秒后<span v-if="isCanClose()">&nbsp;</span
            ><span :class="{ canClose: isCanClose() }">关闭</span>
          </span>
          <span v-else>
            {{ adsTimeLongVideo }}秒后关闭
            <span class="vipClose">VIP跳过广告</span>
          </span> -->
      <!-- </div> -->
      <!-- <div v-else class="vipClose">关闭</div> -->
      <!-- </div> -->

      <div class="rightContent" v-if="videoInfo">
        <div
          class="vipBtn"
          @click="vipOpen"
          v-if="
            videoInfo.listenPermission == 0 &&
            !videoInfo.hasPaid &&
            !userInfo.isVip
          "
        >
          <span class="vipTitle">开通会员&nbsp;</span>
          <span>畅享完整内容</span>
        </div>

        <div
          class="goldenBtn"
          @click="goldenOpen"
          v-if="
            videoInfo.listenPermission == 1 &&
            !videoInfo.hasPaid &&
            !(
              videoInfo.listenPermission == 1 &&
              videoInfo.hasPaid &&
              userInfo.isVip
            )
          "
        >
          <span class="goldenTitle">金币解锁&nbsp;</span>
          <span>畅享完整内容</span>
        </div>
      </div>
    </div>
    <!-- <div class="videoPlay" :key="showVideoAdv"> -->
    <div class="videoPlay">
      <!-- 视频广告 -->
      <!-- <div
        class="videoAdv"
        v-if="
          videoAdv &&
          videoInfo &&
          !(
            videoInfo.listenPermission == 1 &&
            videoInfo.hasPaid &&
            userInfo.isVip
          ) &&
          showVideoAdv
        "
        @click="goAdv(videoAdv)"
      >
        <ImgDecypt class="advImg" :src="videoAdv.cover" :key="videoAdv.cover" />
      </div> -->
      <!-- <div
        v-else
        id="dplayer"
        ref="dplayer"
        playsinline
        webkit-playsinline
      ></div> -->
      <div id="dplayer" ref="dplayer" playsinline webkit-playsinline></div>
    </div>
    <div class="newVip" v-if="countdownSec">
      <div class="Diamond">
        <img src="@/assets/png/Diamond.png" alt="" />
      </div>
      <div class="newUserText">新用户升级会员特惠</div>
      <div>{{ timeStr || `00:00:00` }}</div>
      <div class="immediately">
        <router-link tag="div" to="/rechargePage?t=vip" class="openVip">
          <img src="@/assets/png/immediately.png" alt="" />
        </router-link>
      </div>
    </div>
    <div class="content">
      <div class="contentBar">
        <van-tabs
          class="tabs"
          animated
          background="#FFFFFF"
          color="#666666"
          title-inactive-color="#666666"
          title-active-color="#333333"
          line-width="21"
          line-height="3"
          v-model="activeTab"
        >
          <van-tab title="简介" class="tabContent oepnX">
            <PullRefresh
              v-if="!isLoading"
              :disabled="true"
              :is-higeht-max="true"
              :adv-list="advList"
              :hasAdvPagination="true"
              className="VideoDetailsPage"
            >
              <!--              <div class="horizontalLine" v-if="gridAdvList.length > 0"></div>-->
              <div class="videoContent" v-if="videoData">
                <div class="videoInfo">
                  <div class="videoInfoHead">
                    <div class="videoInfoTitle">
                      <span>{{ videoData.title }}</span>
                    </div>
                    <div
                      class="videoInfoIntroduction"
                      @click="introductionShow = true"
                    >
                      <span>简介</span>
                      <!--                                        <img src="@/assets/png/videoRight.png">-->
                    </div>
                  </div>
                  <div class="videoInfoTag">
                    <span
                      v-for="(item, index) in videoData.tagNames"
                      :key="index"
                      @click="tagJump(item, index)"
                      >{{ `# ` + item }}</span
                    >
                  </div>
                  <!-- 选集 -->
                  <div class="videoSelection">
                    <div class="titleHead">
                      <div class="titleName">
                        <span>选集</span>
                      </div>
                      <div class="titleRight" @click="directoryShow = true">
                        <span>全{{ videoData.contents.length }}集</span>
                        <!--                                        <img src="@/assets/png/videoRight.png">-->
                      </div>
                    </div>
                    <div class="anthology oepnX">
                      <div
                        class="anthologyItem"
                        v-for="(item, index) in videoData.contents"
                        @click="anthology(item, index)"
                        :key="index"
                      >
                        <div>
                          <!-- vip -->
                          <div
                            class="vipLabel"
                            v-if="item.listenPermission == 0"
                          >
                            <span>VIP</span>
                          </div>

                          <!-- 金币 -->
                          <div
                            class="goldenLabel"
                            v-if="item.listenPermission == 1"
                          >
                            <span
                              ><svg-icon
                                class="Golden"
                                iconClass="Golden"
                              ></svg-icon
                              >{{ item.price }}</span
                            >
                          </div>

                          <!-- 免费 -->
                          <div
                            class="freeLabel"
                            v-if="item.listenPermission == 2"
                          >
                            <!--                               <span>免费</span>-->
                          </div>

                          <div
                            class="anthologyTxt"
                            :class="{ activeItem: activeIndex == index }"
                          >
                            <span>
                              {{ index + 1 }}
                            </span>
                          </div>

                          <!-- <div class="activePlay" v-if="activeIndex == index">
              <img src="@/assets/gif/activePlay.gif" />
            </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="videoInfoBottom">
                    <div class="videoInfoPlayNum">
                      <img src="@/assets/png/videoPlay.png" />
                      <span>{{ videoData.countBrowse | watchCount }}</span>
                    </div>
                    <!-- 收藏 -->
                    <div
                      class="videoCollect"
                      v-if="videoData && !videoData.hasCollected"
                      @click="collectionWorks"
                    >
                      <img src="@/assets/png/collectBtn.png" />
                      <span>收藏</span>
                    </div>
                    <div
                      class="videoCollect"
                      v-else-if="videoData"
                      @click="cancelCollectionWorks"
                    >
                      <img src="@/assets/png/hasCollectBtn.png" />
                      <span>已收藏</span>
                    </div>
                    <div class="videoPraise">
                      <img
                        src="@/assets/png/videoPraise.png"
                        @click="doLike"
                        v-if="!videoData.hasLiked"
                      />
                      <img
                        src="@/assets/png/videoPraiseActive.png"
                        @click="doUnlike"
                        v-else
                      />
                      <span>{{ videoData.countLike | watchCount }}</span>
                    </div>
                    <div @click.stop="videoShare(videoData)" class="videoShare">
                      <img src="@/assets/png/videoShare.png" />
                      <span>分享</span>
                    </div>
                  </div>
                </div>
                <div class="advBox" v-if="gridAdvList.length">
                  <div
                    class="advItem"
                    v-for="(item, index) in gridAdvList"
                    :key="index"
                    @click="goAdv(item)"
                  >
                    <div class="advImgBox">
                      <ImgDecypt :src="item.cover" :key="item.cover" />
                    </div>
                    <div class="advTitle">{{ item.title }}</div>
                  </div>
                </div>
                <div class="horizontalLine" v-if="gridAdvList.length > 0"></div>

                <!-- 广告 -->
                <!-- <div class="advertising" v-if="advInfo" @click="goAdv(advInfo)">
                        <ImgDecypt :key="advInfo.cover" :src="advInfo.cover" />
                    </div> -->
                <div class="advertising" v-if="gridAdvList.length <= 0">
                  <van-swipe
                    class="swipe"
                    :autoplay="3000"
                    indicator-color="#FECD55"
                  >
                    <van-swipe-item
                      v-for="(item, index) in advInfo"
                      :key="index"
                      @click="goAdv(item)"
                    >
                      <ImgDecypt class="advImg" :src="item.cover" />
                    </van-swipe-item>
                  </van-swipe>
                </div>

                <!-- 推荐 -->
                <div class="recommendVideo">
                  <div class="recommendTitle">
                    <span>大家正在看</span>
                  </div>
                  <div>
                    <TransverselyTwoList
                      v-if="kind == 3"
                      :videoList="randomList"
                    ></TransverselyTwoList>
                    <VerticleThreeList
                      v-else
                      :videoList="randomList"
                    ></VerticleThreeList>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 300px"></div>
            </PullRefresh>
            <Loading v-else />
          </van-tab>
          <van-tab class="tabContent oepnX">
            <template #title>
              <div class="tabItemTitle">
                <div>评论</div>
                <div class="comTotle">{{ msgTotle | watchCount }}</div>
              </div>
            </template>
            <!--  添加没有评论框    :isNoData="isNoData"-->
            <PullRefresh
              v-if="!isLoading"
              :loading="loading"
              :refreshing="refreshing"
              :finished="finished"
              @onLoad="onLoad"
              @onRefresh="onRefresh"
              :error="error"
              :is-higeht-max="true"
              :adv-list="advList"
              :hasAdvPagination="true"
              className="VideoDetailsPage"
            >
              <!-- <div class="commentsContent" v-if="commentList.length > 0"> -->
              <!-- <div class="commentsHead">
         <span>全部评论</span>
       </div> -->
              <!-- <div class="commentsContentList">
         <div
           class="msgItem"
           v-for="item in commentList"
           :key="item.id"
         >
           <div class="msgTopBox">
             <div @click="jumpUserPage(item)">
               <ImgDecypt
                 class="avatar"
                 :src="item.userPortrait"
                 :key="item.userPortrait"
                 round
               />
             </div>
             <div class="infoBox">
               <div class="uName" @click.stop="replyMsg(item)">
                 {{ item.userName }}
               </div>
               <div class="dateInfo">
                 {{ item.createdAt | commentTime }}
               </div>
             </div>
             <div class="likeBox" @click.stop="likeComment(item)">
               <svg-icon
                 class="likeIcon"
                 :iconClass="item.isLike ? 'redHeart' : 'grey_heart'"
               />
               <div>{{ item.likeCount }}</div>
             </div>
           </div>
           <div class="msgContent" @click.stop="replyMsg(item)">
             {{ item.content }}
           </div>
           <div
             class="msgLevel2"
             v-if="item.Info && item.Info.length > 0"
           >
             <div class="msgBoxLv2" v-for="i in item.Info" :key="i.id">
               <div class="nameLv2">{{ i.userName }}</div>
               <div class="msgLv2">{{ i.content }}</div>
             </div>
             <div class="msgNum" @click.stop="jumpDetails(item)">
               共{{ item.commCount || 0 }}条回复>
             </div>
           </div>
         </div>
       </div> -->
              <!-- </div> -->
              <div class="commentsContent">
                <div class="commentsContentList" v-if="commentList.length > 0">
                  <div
                    class="commentBox"
                    v-for="item in commentList"
                    :key="item.id"
                  >
                    <div class="commentInfo">
                      <ImgDecypt
                        class="avatar"
                        round
                        :src="item.userPortrait"
                        :key="item.userPortrait"
                      />
                      <div class="info">
                        <div class="name">
                          <span>{{ item.userName }}</span>
                          <!--                          <svg-icon-->
                          <!--                            :iconClass="-->
                          <!--                              item.gender == 'male' ? 'maleMsg' : 'female'-->
                          <!--                            "-->
                          <!--                          ></svg-icon>-->
                        </div>
                        <div class="text msgContent" v-if="!item.isDelete">
                          <div style="width: calc(100% - 22px)">
                            <CommentJump
                              class="commit_content"
                              :text="item.content"
                              :keyword="item.searchKeyword"
                              :linkType="item.linkType"
                              :linkStr="item.linkStr"
                            />
                          </div>
                        </div>
                        <div
                          class="text"
                          style="color: rgb(153, 153, 153)"
                          v-else
                        >
                          内容已被删除
                        </div>
                        <div class="address" v-if="!item.isTop">
                          <div class="createdAt">
                            <span>{{ item.createdAt | time }}</span>
                          </div>

                          <div class="btnBox" v-if="!item.linkType">
                            <span @click.stop="reply(item, null)">
                              <svg-icon
                                class="community_info"
                                iconClass="community_info"
                              />
                            </span>

                            <span @click.stop="likeComment(item)">
                              <svg-icon
                                class="like icon"
                                v-if="item.isLike"
                                iconClass="postLikeedIcon"
                              />
                              <svg-icon
                                class="like icon"
                                v-else
                                iconClass="postLikeIcon"
                              />
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <span
                            style="color: rgb(153, 153, 153); font-size: 12px"
                            >------此評論系統生成 無法回复</span
                          >
                        </div>

                        <div class="commentBox" style="border-bottom: none">
                          <div
                            class="commentInfo"
                            v-if="item.Info && item.Info.length > 0"
                            style="margin-bottom: 10px"
                          >
                            <ImgDecypt
                              class="avatar"
                              round
                              :src="item.Info[0].userPortrait"
                              :key="item.Info[0].userPortrait"
                            />
                            <div class="childInfo">
                              <div class="name">
                                <span>{{ item.Info[0].userName }}</span>
                                <!--                                <svg-icon-->
                                <!--                                  :iconClass="-->
                                <!--                                    item.Info[0].gender == 'male'-->
                                <!--                                      ? 'male'-->
                                <!--                                      : 'female'-->
                                <!--                                  "-->
                                <!--                                ></svg-icon>-->
                                <svg-icon
                                  v-if="
                                    item.Info[0].toUserID != 0 &&
                                    item.Info[0].toUserID != null
                                  "
                                  class="rightCommIcon"
                                  iconClass="rightCommIcon"
                                ></svg-icon>
                                <span v-if="item.Info[0].toUserID">{{
                                  item.Info[0].toUserName
                                }}</span>
                              </div>
                              <div
                                class="text msgContent"
                                v-if="!item.Info[0].isDelete"
                              >
                                <div style="width: calc(100% - 22px)">
                                  <CommentJump
                                    class="commit_content"
                                    :text="item.Info[0].content"
                                    :keyword="item.Info[0].searchKeyword"
                                    :linkType="item.Info[0].linkType"
                                    :linkStr="item.Info[0].linkStr"
                                  />
                                </div>
                              </div>
                              <div
                                class="text"
                                style="color: rgb(153, 153, 153)"
                                v-else
                              >
                                内容已被删除
                              </div>
                              <div class="address">
                                <div class="createdAt">
                                  <span>{{
                                    item.Info[0].createdAt | time
                                  }}</span>
                                </div>

                                <div
                                  class="btnBox"
                                  v-if="!item.Info[0].linkType"
                                >
                                  <span @click.stop="reply(item.Info[0], item)">
                                    <svg-icon
                                      class="community_info"
                                      iconClass="community_info"
                                    />
                                  </span>

                                  <span @click.stop="likeComment(item.Info[0])">
                                    <svg-icon
                                      class="like icon"
                                      v-if="item.Info[0].isLike"
                                      iconClass="postLikeedIcon"
                                    />
                                    <svg-icon
                                      class="like icon"
                                      v-else
                                      iconClass="postLikeIcon"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="item.isShow">
                            <div
                              class="commentInfo"
                              v-for="subComment in item.Info.slice(1)"
                              :key="subComment.id"
                              style="margin-bottom: 10px"
                            >
                              <ImgDecypt
                                class="avatar"
                                round
                                :src="subComment.userPortrait"
                                :key="item.userPortrait"
                              />
                              <div class="childInfo">
                                <div class="name">
                                  <span>{{ subComment.userName }}</span>
                                  <!--                                  <svg-icon-->
                                  <!--                                    :iconClass="-->
                                  <!--                                      subComment.gender == 'male'-->
                                  <!--                                        ? 'male'-->
                                  <!--                                        : 'female'-->
                                  <!--                                    "-->
                                  <!--                                  ></svg-icon>-->
                                  <svg-icon
                                    v-if="
                                      subComment.toUserID != 0 &&
                                      subComment.toUserID != null
                                    "
                                    class="rightCommIcon"
                                    iconClass="rightCommIcon"
                                  ></svg-icon>
                                  <span
                                    v-if="
                                      subComment.toUserID != 0 &&
                                      subComment.toUserID != null
                                    "
                                    >{{ subComment.toUserName }}</span
                                  >
                                </div>
                                <div
                                  class="text msgContent"
                                  v-if="!subComment.isDelete"
                                >
                                  <div style="width: calc(100% - 22px)">
                                    <CommentJump
                                      class="commit_content"
                                      :text="subComment.content"
                                      :keyword="subComment.searchKeyword"
                                      :linkType="subComment.linkType"
                                      :linkStr="subComment.linkStr"
                                    />
                                  </div>
                                </div>
                                <div
                                  class="text"
                                  style="color: rgb(153, 153, 153)"
                                  v-else
                                >
                                  内容已被删除
                                </div>
                                <div class="address">
                                  <div class="createdAt">
                                    <span>{{
                                      subComment.createdAt | time
                                    }}</span>
                                  </div>

                                  <div
                                    class="btnBox"
                                    v-if="!subComment.linkType"
                                  >
                                    <span @click.stop="reply(subComment, item)">
                                      <svg-icon
                                        class="community_info"
                                        iconClass="community_info"
                                      />
                                    </span>

                                    <span @click.stop="likeComment(subComment)">
                                      <svg-icon
                                        class="like icon"
                                        v-if="subComment.isLike"
                                        iconClass="postLikeedIcon"
                                      />
                                      <svg-icon
                                        class="like icon"
                                        v-else
                                        iconClass="postLikeIcon"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Loading
                            class="sec-loading"
                            v-if="
                              commentParams.currentFirstItem.id == item.id &&
                              commentParams.currentLoading
                            "
                          />
                          <div
                            class="load-more"
                            v-else-if="
                              !commentParams.currentLoading &&
                              commentParams.currentFirstItem.id == item.id &&
                              commentParams.currentHasNext
                            "
                            @click.stop="loadMoreSubComment(item)"
                          >
                            — 点击加载更多评论
                          </div>
                        </div>
                      </div>
                      <img
                        class="godMsg"
                        v-if="item.isGodComment"
                        src="@/assets/png/godMsg.png"
                      />
                    </div>
                    <div
                      class="expand-box"
                      @click.stop="onShow(item)"
                      v-if="item.commCount && item.commCount > 1"
                    >
                      {{
                        item.isShow
                          ? "— 收起"
                          : `— 展开${item.commCount - 1}条回复`
                      }}
                      <svg-icon
                        iconClass="expand"
                        :class="[
                          { 'rotate-target': item.isShow },
                          { 'rotate-burden-target': !item.isShow },
                        ]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 350px"></div>
              <!-- <div class="tabBar" @click="showInput">
         <div class="comments">
           <span>发条有爱的评论吧</span>
           <img src="@/assets/png/commentsEditor.png" />
         </div>
         <div class="placeholder"></div>
       </div> -->
              <!-- <div class="tabBars">
         <div class="comments">
           <van-field
             class="Inputs"
             ref="newInput"
             v-model="msgVal"
             :border="false"
             :placeholder="placeholderText"
             @blur="changBlur"
           />
           <div
             class="sendBtn"
             :class="{ activeBtn: msgVal }"
             @click="sendMsg"
           >
             <img src="@/assets/png/commentsEditor.png" />
           </div>
         </div>
       </div> -->
            </PullRefresh>
            <Loading v-else />
            <!-- <div v-if="activeTab === 1" class="tabBars">
        <div class="comments">
         <van-field
           class="Inputs"
           ref="commentInputBoxRef"
           v-model="commentParams.content"
           :border="false"
           :placeholder="placeholder"
           @blur="changBlur"
         />
         <div class="sendBtn" :class="{ activeBtn: msgVal }" @mousedown="handleMousedown">
          <img src="@/assets/png/commentsEditor.png"/>
         </div>
        </div>
       </div> -->
          </van-tab>
        </van-tabs>
        <!-- 选择路线弹窗 -->
        <SelectLine class="rerouteBox" @isChange="selectChange" />
      </div>
    </div>

    <div v-if="activeTab === 1" class="defaultInput">
      <div class="inputBox">
        <van-field
          ref="commentInputBoxRef"
          class="newInput"
          v-model="commentParams.content"
          :border="false"
          :placeholder="placeholder"
          @blur="changBlur"
        />
        <div
          class="sendBtn"
          :class="{ activeBtn: msgVal }"
          @mousedown="handleMousedown"
        ></div>
      </div>
    </div>
    <!-- 简介弹窗 -->
    <van-popup
      v-model="introductionShow"
      position="bottom"
      :style="{ height: 'calc(100% - 221px)', background: 'rgb(255,255,255)' }"
    >
      <div class="introductionContent" v-if="videoData">
        <div class="introductionBar">
          <div class="title">
            <span>简介</span>
          </div>
          <div class="btn" @click="introductionShow = false">
            <img src="@/assets/png/popupClose.png" />
          </div>
        </div>
        <div class="introductionTitle">
          <span>{{ videoData.title }}</span>
        </div>
        <div class="introductionTag">
          <span v-for="(item, index) in videoData.tagNames" :key="index">
            {{ `# ` + item }}
          </span>
        </div>
        <div class="introductionDetails">
          <span>{{ videoData.summary }}</span>
        </div>
      </div>
    </van-popup>

    <!-- 目录弹窗 -->
    <van-popup
      v-model="directoryShow"
      position="bottom"
      :style="{ height: '511px', background: 'rgb(255,255,255)' }"
    >
      <div class="directoryContent" v-if="videoData">
        <div class="directoryBar">
          <div class="title">
            <span>目录（全{{ videoData.contents.length }}集）</span>
          </div>
          <div class="btn" @click="chapterSort">
            <span v-if="sorting">倒序</span>
            <span v-else>升序</span>
          </div>
          <!-- <div class="btn" @click="directoryShow = false">
       <img src="@/assets/png/popupClose.png" />
     </div> -->
        </div>
        <div class="anthologyContent">
          <div
            class="anthologyItem"
            v-for="(item, index) in videoData.contents2"
            @click="anthology(item, index)"
            :key="index"
          >
            <div>
              <!-- vip -->
              <div class="vipLabel" v-if="item.listenPermission == 0">
                <span>VIP</span>
              </div>

              <!-- 金币 -->
              <div class="goldenLabel" v-if="item.listenPermission == 1">
                <span
                  ><svg-icon class="Golden" iconClass="Golden"></svg-icon
                  >{{ item.price }}</span
                >
              </div>

              <!-- 免费 -->
              <div class="freeLabel" v-if="item.listenPermission == 2">
                <!--                               <span>免费</span>-->
              </div>

              <div
                class="anthologyTxt"
                :class="{ activeItem: activeIndex2 == index }"
              >
                <span>
                  {{ item.episodeNumber }}
                </span>
              </div>

              <!-- <div class="activePlay" v-if="activeIndex === index"> -->
              <!-- <img src="@/assets/gif/activePlay.gif" /> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- <van-popup
    class="inputPop"
    v-model="isShowInput"
    position="bottom"
    @close="closeInput"
  >
    <div class="inputBox">
      <van-field
        class="newInput"
        ref="newInput"
        v-model="msgVal"
        :border="false"
        :placeholder="placeholderText"
        @blur="changBlur"
      />
      <div class="sendBtn" :class="{ activeBtn: msgVal }" @click="sendMsg">
        发送
      </div>
    </div>
  </van-popup> -->
    <!-- 切换路线框 -->
    <!-- <SelectionLinePopup
    v-if="showChangeRoadLine"
    @closeSelectLine="closeSelectLine"
    @close="showChangeRoadLine = false"
  /> -->
  </div>
</template>

<script>
import {
  queryMediaInfoList,
  queryRandomInfoList,
  doCollect,
  douUcollect,
  doLike,
  doUnlike,
} from "@/api/video";
import { setSessionItem } from "@/utils/longStorage";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import DPlayer from "dplayer";
import { getLocalItem } from "@/utils/longStorage";
import { mapGetters } from "vuex";
import { queryCommentList } from "@/api/user";
import ImgDecypt from "@/components/ImgDecypt";
import { sendV2Comment, thumbsDownComment, thumbsUpComment } from "@/api/mine";
import { Toast } from "vant";
import { queryCommentListlever2 } from "@/api/user";

import { addVideoHistory, queryHistoryId } from "@/utils";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
// import SelectionLinePopup from "@/components/SelectionLinePopup";
import SelectLine from "@/components/SelectLine";
import { queryTiroCountdown } from "@/api/mine";
import CommentJump from "@/components/CommentJump";

export default {
  name: "VideoDetailsPage",
  components: {
    VerticleThreeList,
    TransverselyTwoList,
    PullRefresh,
    Loading,
    ImgDecypt,
    SelectLine,
    CommentJump,
    // SelectionLinePopup,
  },
  data() {
    return {
      timeStr: "",
      countdownSec: 0, // 新手倒计时时间 单位秒
      countdownFncs: "",
      activeTab: 0,
      atlasData: null,
      currMsgItem: "",
      mediaID: null,
      kind: null,
      videoData: null,
      videoInfo: null,
      randomList: [],
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      advList: [],
      gridAdvList: [],
      introductionShow: false,
      directoryShow: false,
      commentList: [],
      playTimer: null,
      isShowInput: false,
      placeholder: "请在此输入评论",
      msgVal: "",
      cid: undefined,
      userId: undefined,
      level: 1,
      activeIndex: 0,
      activeIndex2: 0,
      postInfo: {},
      advInfo: null,
      isReport: false,
      pageNumber: 1,
      pageSize: 10,
      msgTotle: 0,
      // videoAdv: null,
      // adsTimeLongVideo: 0,
      // countdownFnc: null,
      // showVideoAdv: true,
      showChangeRoadLine: false, // 显示切换路线
      sorting: true,
      commentParams: {
        objID: "", // 帖子ID,
        level: 1, // 评论级别，1: 一级评论;2: 二级评论
        content: "", // 评论内容
        cid: "", // 一级评论的ID
        userID: "", // 一级评论的用户ID
        currentFirstItem: {}, // 当前一级评论对象
        currentHasNext: false, // 当前评论是否还有下一页数据
        currentLoading: false, // 当前二级评论的loading
        currentShowInputId: "", // 当前显示的输入框id
        rid: "",
        toUserID: "",
      }, // 评论参数
      secPageNumber: 1, // 二级评论页码
      secPageSize: 10, // 二级评论一页的数量
    };
  },
  computed: {
    ...mapGetters({
      baseUrl: "baseUrl",
      userInfo: "userInfo",
      isVip: "isVip",
      videoRoadLine: "videoRoadLine", // 选择的线路
    }),
  },
  beforeDestroy() {
    clearTimeout(this.countdownFncs);
  },
  created() {
    this.queryTiroCountdown();
  },
  mounted() {
    let query = this.$route.query;
    let advList = getAdItem(AdType.COMMUNITY_DETAILS);
    this.gridAdvList = getAdItem(AdType.VIDEODETAILROWADV).length
      ? getAdItem(AdType.VIDEODETAILROWADV)
      : [];
    this.getVideoAdv();
    if (advList.length > 0) {
      this.advInfo = advList;
    }
    if (query) {
      this.mediaID = query.id;
      this.kind = query.kind;
      this.activeIndex = 0;
      this.activeIndex2 = 0;
      this.isLoading = true;
      this.getMediaInfoList();
      this.getRandomInfoList();
      this.getCommentList();
    }
  },
  methods: {
    // 查询新手优惠时间
    async queryTiroCountdown() {
      let res = await this.$Api(queryTiroCountdown);
      const { data } = res;
      console.log(res);
      if (res && res.code === 200) {
        this.countdownSec = data.countdownSec;
        this.countdown(this.countdownSec);
      }
    },
    // 倒计时
    countdown(timer) {
      if (timer <= 0) return;
      // 相差的毫秒数
      const msec = timer;
      // 计算时分秒数
      let hr = parseInt(msec / 60 / 60);
      let min = parseInt((msec / 60) % 60);
      let sec = parseInt(msec % 60);
      // 个位数前补零
      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      this.timeStr = `${hr}:${min}:${sec}`;
      // 控制台打印
      // console.log(` ${hr}小时 ${min}分钟 ${sec}秒`)
      // 一秒后递归
      this.countdownFncs = setTimeout(() => {
        timer--;
        this.countdown(timer);
      }, 1000);
    },
    async onShow(comment) {
      console.log(comment);
      if (comment.isShow) {
        this.$set(comment, "isShow", false);
      } else {
        this.commentParams.currentHasNext = true; // 初始化当前评论默认有下一页
        this.commentParams.currentFirstItem = comment;
        this.commentParams.currentLoading = true;
        this.secPageNumber = 1;
        await this.getSecondList(comment);
        this.$set(comment, "isShow", true);
      }
    },
    async getSecondList(comment) {
      /**
       * 获取二级评论
       */
      let req = {
        objID: comment.objID,
        cmtId: comment.id,
        fstID: comment.Info[0].id, // 第一条评论的ID
        curTime: new Date().toISOString(),
        pageNumber: this.secPageNumber,
        pageSize: this.secPageSize,
      };

      try {
        let res = await this.$Api(queryCommentListlever2, req);
        this.commentParams.currentLoading = false;
        if (res.data.list && res.data.list.length > 0) {
          let commentNo = [comment.Info[0]];
          if (this.secPageNumber == 1) {
            comment.Info = commentNo.concat(res.data.list);
          } else {
            comment.Info = commentNo.concat(comment.Info.concat(res.data.list));
          }
        }
        if (!res.data.hasNext) {
          this.commentParams.currentHasNext = false;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    async getCommentList(type) {
      /**
       * 评论列表
       */
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        curTime: new Date().toISOString(),
        objID: this.mediaID,
      };
      let res = await this.$Api(queryCommentList, req);

      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      if (res && res.code == 200) {
        if (res.data.list) {
          if (type == "refresh") {
            this.commentList = res.data.list;
          } else {
            this.commentList = this.commentList.concat(res.data.list);
          }
        }

        if (this.pageNumber === 1 && this.commentList.length === 0) {
          this.isNoData = true;
        }
        if (
          !res.data.hasNext ||
          (res.data.list && res.data.list.length < req.pageSize)
        ) {
          this.finished = true;
        }
      }
    },
    jumpUserPage(item) {
      this.$router.replace({
        path: "/userHomePage",
        query: {
          uid: item.userID,
        },
      });
      this.$store.commit("user/SET_COMMENTPOP", { show: false, info: {} });
      if (this.$route.path == "/userHomePage") {
        this.reload();
      }
    },
    showInput() {
      let _this = this;

      /**
       * 加入了判断是否VIP
       */
      if (this.userInfo.isVip && this.userInfo.vipLevel) {
        this.level = 1;
        this.isShowInput = true;
        this.$nextTick(() => {
          if (this.$refs.newInput) {
            this.$refs.newInput.focus();
          }
        });
      } else {
        _this.$bus.$emit("vipPopup", {
          state: 1,
          closeBtn: () => {
            _this.$bus.$emit("closeVipPopup");
          },
        });
      }
    },
    closeInput() {
      this.cid = undefined;
      this.userId = undefined;
      this.msgVal = "";
      this.level = 1;
    },
    // 发送时阻止ios默认事件
    async handleMousedown(event) {
      event.preventDefault();
      this.$refs.commentInputBoxRef.blur();
      await this.sendMsg();
    },
    async sendMsg() {
      if (!this.commentParams.content) {
        this.$toast("请输入评论");
        return;
      }
      let req = {};
      if (this.commentParams.level == 1) {
        // 一级评论参数
        req = {
          objID: this.mediaID,
          level: 1,
          content: this.commentParams.content,
          cmtType: "media",
          cid: this.cid,
        };
      } else {
        // 二级评论参数
        if (this.commentParams.toUserID) {
          req = {
            objID: this.mediaID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            rid: this.commentParams.rid,
            toUserID: this.commentParams.toUserID,
            cmtType: "media",
          };
        } else {
          req = {
            objID: this.mediaID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            cmtType: "media",
          };
        }
      }
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(sendV2Comment, req);
        this.$store.commit("app/SET_LOADING", false);
        this.commentParams.content = ""; // 评论输入框内容清空
        // this.lfCount++; // 评论+1
        // this.msgTotle++; // 评论+1
        // if (this.commentParams.level == 1) {
        //   this.isNoData = false;
        //   this.commentList.unshift(res.data);
        // } else if (this.commentParams.level == 2) {
        //   this.commentList = this.commentList.map((item) => {
        //     if (item.id == this.commentParams.currentFirstItem.id) {
        //       item.isShow = true;
        //       item.Info.unshift(res.data);
        //     }
        //     return item;
        //   });
        // }
        this.clearReply();
        this.$toast("评论成功，等待审核");
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("评论失败，请稍后再试");
      }
    },
    clearReply() {
      this.commentParams.currentFirstItem = {};
      this.commentParams.objID = "";
      this.commentParams.cid = "";
      this.commentParams.userID = "";
      this.commentParams.level = 1;
      this.placeholder = `我怀疑你也想开车`;
    },
    // 回复-显示输入框
    replyMsg(item) {
      this.placeholder = `回复 @${item.userName}`;
      this.cid = item.id;
      this.userId = item.userId;
      this.level = 2;
      this.isShowInput = true;
      this.currMsgItem = item;
      this.$nextTick(() => {
        if (this.$refs.newInput) {
          this.$refs.newInput.focus();
        }
      });
    },
    async loadMoreSubComment(comment) {
      /**
       * 获取更多二级评论
       * @type {boolean}
       */
      this.commentParams.currentLoading = true;
      this.secPageNumber++;
      await this.getSecondList(comment);
    },
    reply(item, parentItem) {
      /**
       * 二级评论输入框显示事件
       */
      if (parentItem != null) {
        this.commentParams.currentFirstItem = parentItem;
        this.commentParams.rid = item.id;
        this.commentParams.toUserID = item.userID;
        this.commentParams.cid = parentItem.id;
      } else {
        this.commentParams.currentFirstItem = item;
        this.commentParams.rid = "";
        this.commentParams.toUserID = "";
        this.commentParams.cid = item.id;
      }
      this.commentParams.objID = item.objID;
      this.commentParams.userID = item.userID;
      this.commentParams.level = 2;
      this.placeholder = `回复@${item.userName}`;
      this.$refs.commentInputBoxRef.focus();
    },
    // async sendMsg() {
    //   let req = {
    //     cid: this.cid,
    //     content: this.msgVal,
    //     level: this.level,
    //     objID: this.mediaID,
    //     cmtType: "media",
    //   };
    //   if (!req.content) return;
    //   this.$store.commit("app/SET_LOADING", true);
    //   try {
    //     let res = await this.$Api(sendV2Comment, req);
    //     this.$store.commit("app/SET_LOADING", false);
    //     if (res.code === 200) {
    //       if (this.currMsgItem) {
    //         this.currMsgItem.Info.unshift(res.data);
    //         this.currMsgItem.commCount = this.currMsgItem.commCount + 1;
    //       } else {
    //         this.commentList.unshift(res.data);
    //       }
    //       this.msgTotle = this.msgTotle + 1;
    //       this.isShowInput = false;
    //       Toast("评论成功");
    //     } else {
    //       Toast(res.tip || "评论失败，请稍后再试");
    //     }
    //   } catch (error) {
    //     // console.log(error);
    //     this.$store.commit("app/SET_LOADING", false);
    //     Toast("评论失败，请稍后再试");
    //   }
    // },
    selectChange() {
      console.log("选线成功");
    },
    chapterSort() {
      //章节排序
      this.sorting = !this.sorting;
      this.videoData.contents2.reverse();
      this.activeIndex2 =
        this.videoData.contents2.length - 1 - this.activeIndex2;
    },
    isCanClose() {
      if (
        ((this.videoInfo.listenPermission == 0 ||
          this.videoInfo.listenPermission == 1 ||
          this.videoInfo.listenPermission == 2) &&
          this.userInfo.isVip) ||
        (this.videoInfo.listenPermission == 1 && this.videoInfo.hasPaid) ||
        this.adsTimeLongVideo <= 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    closeAdv() {
      if (this.isCanClose()) {
        clearTimeout(this.countdownFnc);
        this.adsTimeLongVideo = 0;
        this.showVideoAdv = false;
        setTimeout(() => {
          this.initVido();
        }, 100);
      } else {
        this.$router.push("/rechargePage?t=vip");
      }
    },
    advCountdown() {
      // console.log(this.adsTimeLongVideo, "authenticator----------");
      // console.log(this.videoInfo.listenPermission, "authenticator----------");
      // console.log(this.videoInfo.hasPaid, "authenticator----------");
      // console.log(this.userInfo.isVip, "authenticator----------");
      if (
        this.adsTimeLongVideo > 0 &&
        !(
          this.videoInfo.listenPermission == 1 &&
          this.videoInfo.hasPaid &&
          this.userInfo.isVip
        )
      ) {
        this.countdownFnc = setTimeout(() => {
          this.adsTimeLongVideo--;
          this.advCountdown();
        }, 1000);
      } else {
        setTimeout(() => {
          this.initVido();
        }, 100);
      }
    },
    getVideoAdv() {
      let videoAdvs = getAdItem(AdType.VIDEO_ADV);
      let index = Math.floor(Math.random() * videoAdvs.length);
      this.videoAdv = videoAdvs[index];
      console.log(this.videoAdv);
    },
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    async getMediaInfoList() {
      let req = {
        mediaID: this.mediaID,
      };
      let res = await this.$Api(queryMediaInfoList, req);
      const { data } = res;
      this.isLoading = false;

      if (res && res.code == 200) {
        this.videoData = data;
        this.videoData.contents2 = JSON.parse(
          JSON.stringify(this.videoData.contents)
        );
        console.log(this.videoData);
        this.msgTotle = data.countComment || 0;
        setSessionItem("currentWorks", JSON.stringify(data));
        this.$store.commit("video/SET_CURRENT_WORKS", data);
        if (data.contents && data.contents.length > 0) {
          this.postInfo = data;
          let historyVideoInfo = await queryHistoryId(this.postInfo.id);
          if (historyVideoInfo) {
            this.videoInfo = data.contents[historyVideoInfo.historyIndex || 0];
            this.activeIndex = historyVideoInfo.historyIndex || 0;
            this.activeIndex2 = historyVideoInfo.historyIndex || 0;
          } else {
            this.videoInfo = data.contents[0];
          }
          this.initVido();
          // let appConfig = this.$store.getters.appConfig;
          // this.adsTimeLongVideo = appConfig?.adsTimeLongVideo || 0;
          // this.adsTimeLongVideo = 0; // 将广s告倒计时设为0
          // if (this.adsTimeLongVideo <= 0) {
          //   this.initVido();
          // } else {
          //   this.advCountdown();
          // }
          // console.log(this.videoInfo && this.adsTimeLongVideo > 0)
          // console.log(this.adsTimeLongVideo, "-----------------------")

          // this.initVido();
        }
      }
    },
    async getRandomInfoList() {
      let req = {
        kind: this.kind,
        pageNumber: 1,
        pageSize: 6,
      };
      let res = await this.$Api(queryRandomInfoList, req);
      if (res && res.code == 200) {
        this.randomList = res.data.MediaList;
      }
    },
    anthology(item, index) {
      let _this = this;
      if (!_this.sorting) {
        _this.activeIndex = this.videoData.contents2.length - index - 1;
        _this.activeIndex2 = index;
      } else {
        _this.activeIndex = index;
        _this.activeIndex2 = index;
      }

      _this.videoInfo = item;
      // this.showVideoAdv = true;
      // this.adsTimeLongVideo = 0;
      // this.getVideoAdv();
      // console.log(this.videoAdv);
      if (this.countdownFnc) {
        clearTimeout(this.countdownFnc);
      }
      if (this.player) {
        this.player.pause();
        this.player.destroy();
      }
      // let appConfig = this.$store.getters.appConfig;
      // this.adsTimeLongVideo = appConfig?.adsTimeLongVideo || 0;
      // this.adsTimeLongVideo = 0; // 将广s告倒计时设为0
      // console.log(this.videoInfo && this.adsTimeLongVideo > 0)
      // console.log(this.adsTimeLongVideo, "-----------------------")
      // this.advCountdown();
      if (
        !(
          this.videoInfo.listenPermission == 1 &&
          this.videoInfo.hasPaid &&
          this.userInfo.isVip
        )
      ) {
        setTimeout(() => {
          this.initVido();
        }, 100);
      }
      // _this.player.pause();
      // _this.player.switchVideo({
      //   type: "hls",
      //   url: this.getPath(this.videoInfo.videoUrl),
      // });
      // _this.directoryShow = false;
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getCommentList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.getCommentList("refresh");
    },
    // async addHistory(mediaInfos) {
    //     // addVideoHistory(mediaInfos);
    //     // console.log(mediaInfos)
    //     let videoInfo = await queryHistoryId(mediaInfos.id);
    //     if (videoInfo) {

    //     }
    // },
    initVido() {
      const player = (this.player = new DPlayer({
        container: document.getElementById("dplayer"),
        autoplay: true,
        lang: "zh-cn",
        // hotkey: true,
        screenshot: false,
        preload: "auto",
        hotkey: true,
        video: {
          type: "hls",
          url: this.getPath(this.videoInfo.videoUrl),
        },
      }));
      document.getElementsByClassName("dplayer-full-in-icon")[0].style.display =
        "none";
      player.on("loadedmetadata", () => {
        this.player.video
          .play()
          .then(() => {})
          .catch(() => {
            this.videoLoading(false);
            // this.videoPlayIcon(true);
          });
      });
      player.on("playing", () => {
        // _this.playing();
        this.videoPlayStatus = true;
        player.controller.setAutoHide();
        //视频播放隐藏暂停按钮
        // this.videoPlayIcon(false);
        if (this.player.video.readyState == 4) {
          this.videoLoading(false);
        }
      });
      player.on("loadeddata", () => {
        if (this.videoPlayStatus) {
          //隐藏暂停按钮
          // this.videoPlayIcon(false);
          //隐藏loging按钮
          this.videoLoading(false);
        } else {
          //隐藏暂停按钮
          // this.videoPlayIcon(true);
          //隐藏loging按钮
          this.videoLoading(false);
        }
      });
      player.on("waiting", () => {
        //视频数据正在加载打开loading
        this.videoLoading(true);
        //隐藏暂停按钮
        // this.videoPlayIcon(false);
      });
      player.on("pause", () => {
        // console.log(123123)
        this.pauses();
        this.videoPlayStatus = false;
        //视频暂停显示按钮
        // this.videoPlayIcon(true);
        player.controller.setAutoHide();
        this.videoLoading(false);
      });
      player.on("timeupdate", () => {
        let _this = this;

        if (this.videoPlayStatus) {
          this.videoLoading(false);
        }
        // // player.controller.setAutoHide();
        // // if (!_this.player.playCheck()) {
        // //     player.pause();
        // //     if (this.videoInfo.originCoins == 0) {
        // //         if (this.vipPopList.length > 0) {
        // //             this.isVipCardbuyShow = true;
        // //         } else {
        // //             this.isVipbuyShow = true;
        // //         }
        // //     } else {
        // //         this.isGoldbuyShow = true;
        // //     }
        // // }

        // if (!_this.player) {
        //  return;
        // }

        // let totalTime = _this.player.video.duration;

        // let currentTime = _this.player.video.currentTime;

        // let report = 15;

        // if (totalTime > 300) {
        //  report = totalTime * 0.05;
        // }

        // if (totalTime < 15) {
        //  report = totalTime;
        // }

        // let reportData = {
        //  dataType: 1,
        //  videoId: this.videoInfo.id,
        //  videoTitle: this.videoData.title,
        // };

        // //
        // // let reportData = {
        // //     "dataType": 1,
        // //     "videoId": _this.videoInfo.id,
        // //     "videoTitle": _this.videoInfo.title
        // // };
        // // if (_this.userInfoData['is_vip'] == null) {
        // //     // _this.userInfoData = await this.getUserInfo();
        // // }
        // //
        // // if(!_this.videoInfo || !_this.userInfoData){
        // //     return;
        // // }
        // //
        // //VIP视频 不是VIP
        // if (
        //   _this.videoInfo.listenPermission == 0 &&
        //   !_this.userInfo.isVip &&
        //   !_this.videoInfo.hasPaid
        // ) {
        //  if (currentTime >= report) {
        //   _this.player.fullScreen.cancel("web");
        //   _this.player.pause();
        //   // console.log(_this.videoInfo)
        //   _this.$bus.$emit("vipPopup", {
        //    state: 1,
        //    id: _this.videoInfo.id,
        //    videoType: this.kind,
        //    closeBtn: () => {
        //     _this.$bus.$emit("closeVipPopup");
        //    },
        //    buySuccess: () => {
        //     /**
        //      * 金币购买成功
        //      */
        //     _this.$bus.$emit("closeVipPopup");
        //     this.videoInfo.hasPaid = true;
        //     _this.player.play();
        //     // _this.getMediaInfoList();
        //    },
        //   });

        //   if (!this.isReport) {
        //    this.$bus.$emit("dataBuriedEvent", reportData);
        //    this.isReport = true;
        //   }
        //  }
        // }
        // //金币视频 没有购买
        // else if (
        //   _this.videoInfo.listenPermission == 1 &&
        //   _this.videoInfo.price > 0 &&
        //   !_this.videoInfo.isBuy &&
        //   !_this.videoInfo.hasPaid
        // ) {
        //  if (currentTime >= report) {
        //   player.fullScreen.cancel("web");
        //   _this.player.pause();

        //   // console.log(_this.videoInfo.kind)
        //   _this.$bus.$emit("vipPopup", {
        //    state: 2,
        //    goldenNum: _this.videoInfo.price,
        //    id: _this.videoInfo.id,
        //    videoType: this.kind,
        //    closeBtn: () => {
        //     _this.$bus.$emit("closeVipPopup");
        //    },
        //    buySuccess: () => {
        //     /**
        //      * 金币购买成功
        //      */
        //     _this.$bus.$emit("closeVipPopup");
        //     this.videoInfo.hasPaid = true;
        //     _this.player.play();
        //     // _this.getMediaInfoList();
        //    },
        //   });

        //   if (!this.isReport) {
        //    this.$bus.$emit("dataBuriedEvent", reportData);
        //    this.isReport = true;
        //   }
        //  }
        // } else {
        //  if (!this.isReport && currentTime >= report) {
        //   this.$bus.$emit("dataBuriedEvent", reportData);
        //   this.isReport = true;
        //  }
        // }
        // console.log(this.videoInfo);
        // 如果播放器不存在，或者用户是 VIP，或者视频已经被购买
        if (
          !this.player ||
          (this.userInfo.isVip && this.videoInfo.listenPermission == 0) ||
          this.videoInfo.hasPaid
        ) {
          return;
        }
        // console.log(totalTime, "---------");

        // 获取视频的总时长和当前播放位置
        let totalTime = this.player.video.duration;
        let currentTime = this.player.video.currentTime;

        // 设置非会员观看时间为总时长的 10%，或者 15 秒，取决于哪个更小
        let report = Math.min(totalTime * 0.1, 15);

        // 如果当前播放位置超过非会员观看时间
        if (currentTime >= report) {
          // 如果视频需要 VIP 权限
          if (this.videoInfo.listenPermission == 0) {
            // 取消全屏播放，暂停视频播放，显示 VIP 弹出窗口
            this.player.fullScreen.cancel("web");
            this.player.pause();
            this.$bus.$emit("vipPopup", {
              state: 1,
              id: this.videoInfo.id,
              videoType: this.kind,
              closeBtn: () => {
                this.$bus.$emit("closeVipPopup");
              },
              buySuccess: () => {
                this.$bus.$emit("closeVipPopup");
                this.videoInfo.hasPaid = true;
                this.player.play();
              },
            });
          }
          // 如果视频需要金币购买，价格大于 0，并且视频没有被购买
          else if (
            this.videoInfo.listenPermission == 1 &&
            this.videoInfo.price > 0 &&
            !this.videoInfo.isBuy &&
            !this.videoInfo.hasPaid
          ) {
            // 取消全屏播放，暂停视频播放，显示购买弹出窗口
            this.player.fullScreen.cancel("web");
            this.player.pause();
            this.$bus.$emit("vipPopup", {
              state: 2,
              goldenNum: this.videoInfo.price,
              id: this.videoInfo.id,
              videoType: this.kind,
              closeBtn: () => {
                this.$bus.$emit("closeVipPopup");
              },
              buySuccess: () => {
                this.$bus.$emit("closeVipPopup");
                this.videoInfo.hasPaid = true;
                this.player.play();
              },
            });
          }
        }
      });
    },
    getPath(u) {
      const token = getLocalItem("token");
      // console.log("token:"+token)
      const newUrl =
        this.baseUrl +
        "vid/h5/m3u8/" +
        u +
        "?token=" +
        token +
        "&c=" +
        this.videoRoadLine.url;
      // console.log("newUrl:"+newUrl)
      return newUrl;
    },
    // 播放按钮
    videoPlayIcon() {
      // var dplayerDom = document.getElementById('dplayer');
      // dplayerDom.setAttribute("class", "dplayer-hide-controller");
      // var dplayerBezelIcon =
      //     // document.getElementsByClassName("dplayer-bezel-icon")[0];
      //     document.getElementsByClassName("dplayer-mobile-play")[0];
      // if (!dplayerBezelIcon) {
      //     return;
      // }
      // if (isPause) {
      //     dplayerBezelIcon.style.display = "block";
      // } else {
      //     dplayerBezelIcon.style.display = "none";
      // }
    },
    // 视频loading
    videoLoading(isLoading) {
      //视频加载成功隐藏loading
      var dplayerLoading = document.getElementsByClassName(
        "diplayer-loading-icon"
      )[0];
      if (!dplayerLoading) {
        return;
      }
      if (isLoading) {
        dplayerLoading.style.display = "block";
      } else {
        dplayerLoading.style.display = "none";
      }
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */

      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(doCollect, req);

      if (res.code == 200) {
        this.videoData.hasCollected = true;
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */

      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(douUcollect, req);
      if (res.code == 200) {
        this.videoData.hasCollected = false;
      }
    },
    vipOpen() {
      let _this = this;
      _this.player.pause();
      _this.$bus.$emit("vipPopup", {
        state: 1,
        id: this.videoInfo.id,
        videoType: this.kind,
        closeBtn: () => {
          _this.$bus.$emit("closeVipPopup");
        },
        buySuccess: () => {
          /**
           * 金币购买成功
           */
          _this.$bus.$emit("closeVipPopup");
          this.videoInfo.hasPaid = true;
          _this.player.play();
          // _this.getMediaInfoList();
        },
      });
    },
    goldenOpen() {
      // console.log(this.videoInfo)
      let _this = this;
      _this.player.pause();
      _this.$bus.$emit("vipPopup", {
        id: this.videoInfo.id,
        state: 2,
        goldenNum: this.videoInfo.price,
        videoType: this.kind,
        buySuccess: () => {
          /**
           * 金币购买成功
           */
          _this.$bus.$emit("closeVipPopup");
          this.videoInfo.hasPaid = true;
          _this.player.play();
          // _this.getMediaInfoList();
        },
        closeBtn: () => {
          _this.$bus.$emit("closeVipPopup");
        },
      });
    },
    pauses() {
      clearInterval(this.playTimer);
      this.playTimer = null;
    },
    async likeComment(item) {
      /**
       * 评论点赞
       * @type {{objID, type: string}}
       */
      let req = {
        objID: item.id,
        type: "comment",
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(
          item.isLike ? thumbsDownComment : thumbsUpComment,
          req
        );
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          if (item.isLike) {
            item.likeCount = item.likeCount - 1 || 0;
          } else {
            item.likeCount = item.likeCount + 1;
          }
          item.isLike = !item.isLike;

          Toast(item.isLike ? "点赞成功" : "取消点赞");
        } else {
          Toast(res.tip || "操作失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("操作失败，请稍后再试");
      }
    },
    async doLike() {
      //视频点赞
      let req = {
        kind: 0,
        mediaID: this.mediaID,
      };
      let res = await this.$Api(doLike, req);
      if (res && res.code == 200) {
        this.videoData.hasLiked = true;
        Toast("点赞成功");
      }
    },
    async doUnlike() {
      //视频取消点赞
      let req = {
        kind: 0,
        mediaID: this.mediaID,
      };
      let res = await this.$Api(doUnlike, req);
      if (res && res.code == 200) {
        this.videoData.hasLiked = false;
        Toast("取消点赞");
      }
    },
    videoShare(postInfo) {
      this.$bus.$emit("openSharePopup", postInfo);
    },
    goAdv(item) {
      jumpAdv(item);
    },
    tagJump(item, index) {
      let tagInfo = {
        id: this.videoData.tags[index],
        name: item,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagList") {
        this.$router.replace({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: this.videoData.kind,
            t: Date.now(),
          },
        });
      } else {
        console.log(333);
        this.$router.push({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: this.videoData.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    jumpDetails(item) {
      this.$router.push({
        path: "/commentDetails",
        query: {
          msgInfo: this.encodeBase64(encodeURI(JSON.stringify(item))),
        },
      });
      // this.commentClose();
    },
    // 关闭线路选择
    closeSelectLine() {
      this.initVido();
    },
    showPopup() {
      this.$refs.popupLine.isOpen = true;
    },
  },
  watch: {
    $route(to, from) {
      // console.log(this.showVideoAdv)
      // this.showVideoAdv = true;
      // this.getVideoAdv();
      if (this.countdownFnc) {
        clearTimeout(this.countdownFnc);
      }
      // this.adsTimeLongVideo = 5;
      if (this.player) {
        this.player.pause();
        this.player.destroy();
      }
      // console.log(123123);
      if (to.path == this.$route.path) {
        if (to.query) {
          if (this.mediaID != to.query.id) {
            this.mediaID = to.query.id;
            this.kind = to.query.kind;
            this.activeIndex = 0;
            if (this.player) {
              this.player.pause();
            }
            this.isLoading = true;
            this.getMediaInfoList();
            this.getRandomInfoList();
            this.getCommentList();
          }
        }
      }
      if (this.player && to.path == from.path) {
        this.player.pause();
        this.player.destroy();
      }
    },
  },
  beforeRouteLeave(to, form, next) {
    // console.log(12323)
    if (this.countdownFnc) {
      clearTimeout(this.countdownFnc);
    }
    // this.adsTimeLongVideo = 5;
    if (this.player) {
      this.player.pause();
      this.player.destroy();
    }
    next();
  },
  destroyed() {
    // if (this.player) {
    //   this.player.pause();
    //   this.player.destroy();
    // }
    this.postInfo.historyIndex = this.activeIndex;
    addVideoHistory(this.postInfo);
  },
};
</script>

<style lang="scss" scoped>
.defaultInput {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  z-index: 2004;
  padding: 0 12px 34px;
  box-sizing: border-box;

  /deep/ .van-field__body {
    background: rgba(238, 238, 238, 1);
    padding: 6px 17px;
    border-radius: 16px;
  }

  .inputBox {
    display: flex;
    align-items: center;

    .sendBtn {
      background: url("../../assets/png/sendMsg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .comments {
    height: 59px;
    display: flex;
    justify-content: space-between;
    padding: 19px 24px;
    color: rgb(153, 153, 153);
    //font-weight: bold;
    font-size: 15px;

    img {
      width: 18.2px;
      height: 18.2px;
    }
  }

  .placeholder {
    height: 34px;
  }
}
.videoDetailsPage {
  width: 100%;
  //height: 100%;
  background: rgba(255, 255, 255, 1);

  .headBar {
    display: flex;
    height: 44px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1000;

    .leftIcon {
      padding: 0 12px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .countdown {
      margin-right: 13px;
      font-size: 10px;
      padding: 5px 8px;
      background: rgba(0, 0, 0, 0.66);
      border-radius: 12px;
      color: rgb(255, 255, 255);
    }

    .canClose {
      color: #ff7f0f;
      // span {
      //   color: #ff7f0f;
      // }
    }

    .rightContent {
      padding-right: 13px;

      .vipBtn {
        font-size: 10px;
        padding: 5px 8px;
        background: rgba(0, 0, 0, 0.66);
        border-radius: 12px;
        color: rgb(255, 255, 255);

        .vipTitle {
          color: rgb(253, 227, 156);
        }
      }

      .goldenBtn {
        font-size: 10px;
        padding: 5px 8px;
        background: rgba(0, 0, 0, 0.66);
        border-radius: 12px;
        color: rgb(255, 255, 255);

        .goldenTitle {
          color: rgb(255, 143, 76);
        }
      }
    }
  }

  .videoPlay {
    height: 211px;
    background: rgb(43, 39, 72);
    position: relative;

    #dplayer {
      height: 100%;
    }

    .videoAdv {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  .newVip {
    width: 100%;
    height: 38px;
    //background: #94d6da;
    background: linear-gradient(0deg, #262012 0%, #080703 100%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: rgba(254, 236, 186, 1);
    .newUserText {
      font-size: 12px;
      font-weight: 500;
    }

    .Diamond {
      padding-left: 12px;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 62.14px;
        height: 38px;
      }
    }

    .immediately {
      padding-right: 8px;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 78px;
        height: 24px;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;

    .contentBar {
      width: 100%;
      height: 100%;
      //background: rgb(27,22,76);
      display: flex;
      justify-content: space-between;
      //padding: 0 12px;

      /deep/ .van-tabs__nav--line {
        width: 143px;
      }

      /deep/ .van-tabs__line {
        bottom: 23px;
      }

      /deep/ .van-tabs__wrap {
        background: #ffffff;
        border-bottom: solid 1px rgba(230, 230, 230, 0.63);
      }

      /deep/ .van-tabs__line {
        height: 6px !important;
        width: 20px !important;
        background: #333333;
        bottom: 12.5px !important;
      }

      /deep/ .van-tab--active {
        font-size: 15px;
      }

      /deep/ .van-tab {
        font-size: 15px;
        // width: 51px;
      }

      /deep/ .van-tab__pane {
        // height: calc(100vh - 390px);
        height: calc(100vh - 50px);
      }

      /deep/ .van-tab__text {
        overflow: visible;
      }

      .tabItemTitle {
        // width: 100px;
        position: relative;

        .comTotle {
          position: absolute;
          left: calc(100% + 6px);
          top: 0;
          font-size: 12px;
          font-weight: 400;
          color: rgba(252, 65, 98, 1);
        }
      }

      .tabs {
        //width: 114px;
        width: 100%;
        height: 100%;

        .tabContent {
          //height: calc(100vh - 211px - 51px - 60px);
          .videoContent {
            //height: calc(100vh - 211px - 51px - 92px);
            background: rgba(255, 255, 255, 1);

            .videoInfo {
              border-bottom: solid 1px rgba(230, 230, 230, 0.63);
              padding: 0 12px;

              .videoInfoHead {
                display: flex;
                justify-content: space-between;
                margin: 14px 0;

                .videoInfoTitle {
                  font-size: 18px;
                  font-weight: 500;
                  color: #000000;
                  width: 310px;
                  overflow: hidden; //超出的文本隐藏
                  text-overflow: ellipsis; //溢出用省略号显示
                  white-space: nowrap; // 默认不换行；
                }

                .videoInfoIntroduction {
                  font-size: 15px;
                  color: #000000;

                  img {
                    width: 6.9px;
                    height: 8.6px;
                    margin-left: 6px;
                  }
                }
              }

              .videoInfoTag {
                margin: 0 0 16px 0;
                font-size: 12px;
                color: rgba(51, 51, 51, 1);
                font-weight: 500;
                display: flex;
                align-items: center;
                text-align: center;
                flex-wrap: wrap;

                span {
                  margin-top: 8px;
                  margin-right: 12px;
                  background: rgb(230, 230, 230);
                  border-radius: 16px;
                  font-size: 12px;
                  font-weight: 500;
                  padding: 4px 10px;
                  line-height: 16.8px;
                }
              }

              .videoInfoBottom {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 12px;
                margin-bottom: 12px;

                .videoInfoPlayNum {
                  margin-right: 24px;
                  display: flex;
                  align-items: center;
                  color: #333333;
                }

                .videoCollect {
                  margin-right: 24px;
                  display: flex;
                  align-items: center;
                  color: #333333;
                }

                .videoPraise {
                  margin-right: 24px;
                  display: flex;
                  align-items: center;
                  color: #333333;
                }

                .videoShare {
                  display: flex;
                  align-items: center;
                  color: #333333;
                }

                .menuItem {
                  display: flex;
                  align-items: center;
                  color: #333333;
                  margin-left: 7px;

                  span {
                    margin-left: 3px;
                  }
                }

                img {
                  width: 18px;
                  height: 18px;
                  margin-right: 6.9px;
                }
              }
            }

            .videoSelection {
              margin-top: 18px;
              // border-bottom: solid 1px rgba(230, 230, 230, 0.63);
              padding-bottom: 12px;
              color: #000000;

              .titleHead {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // padding: 0 12px;

                .titleName {
                  font-size: 18px;
                  font-weight: bold;
                }

                .titleRight {
                  font-size: 15px;

                  img {
                    width: 6.9px;
                    height: 8.6px;
                    margin-left: 9px;
                  }
                }
              }

              .anthology {
                display: flex;
                margin-top: 12px;
                overflow-x: auto;
                overflow-y: hidden;
                padding-right: 12px;

                .anthologyItem {
                  //-webkit-flex: 1 0 auto;
                  width: 60px;
                  height: 60px;
                  border-radius: 3px;
                  background: #f6f6f6;
                  margin-right: 12px;
                  font-weight: bold;
                  border-radius: 8px;

                  .activeItem {
                    background: rgba(255, 125, 97, 0.1);
                    border-radius: 8px;
                  }

                  .vipLabel {
                    display: flex;
                    justify-content: flex-end;
                    width: 60px;

                    span {
                      background-image: linear-gradient(
                        to right,
                        #f3cd64,
                        #fde39c
                      );
                      font-size: 10px;
                      width: 24px;
                      height: 12px;
                      color: #663d00;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 3px;
                    }
                  }

                  .goldenLabel {
                    display: flex;
                    justify-content: flex-end;
                    width: 60px;

                    span {
                      .Golden {
                        width: 8px;
                        height: 8px;
                      }
                      background-image: linear-gradient(
                        to right,
                        rgb(255, 57, 87),
                        rgb(255, 89, 34)
                      );
                      font-size: 6px;
                      width: 24px;
                      height: 12px;
                      color: rgb(255, 255, 255);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 3px;
                    }
                  }

                  .freeLabel {
                    height: 12px;
                  }

                  .activePlay {
                    position: relative;
                    top: -22px;
                    left: 7px;

                    img {
                      width: 13px;
                      height: 8px;
                    }
                  }

                  .anthologyTxt {
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: -12px;
                  }
                }

                .anthologyItemActive {
                  color: rgb(68, 224, 209);
                  background: rgb(27, 22, 76);
                }
              }
            }

            .advertising {
              height: 97.56px;
              border-radius: 6px;
              background: rgb(240, 240, 244);
              margin: 16px 12px 0 12px;

              .advImg {
                width: 100%;
                height: 97.56px;

                /deep/ img {
                  object-fit: fill !important;
                }
              }

              /deep/ .van-image {
                border-radius: 8px;
              }

              /deep/ .van-image__img {
                border-radius: 8px;
              }
            }

            .recommendVideo {
              margin-top: 18px;
              padding: 0 12px;

              .recommendTitle {
                font-size: 18px;
                color: rgb(0, 0, 0);
                font-weight: bold;
                margin-bottom: 9px;
              }
            }

            .placeholder {
              height: 100px;
            }
          }

          .commentsContent {
            background: #ffffff;
            //height: calc(100vh - 211px - 51px - 92px);
            color: rgb(0, 0, 0);
            //height: 100%;
            //overflow-y: auto;

            .commentsHead {
              font-size: 18px;
              font-weight: bold;
              margin-top: 18px;
              padding-left: 12px;
            }

            .commentsContentList {
              padding: 18px 12px;

              .commentBox {
                margin-bottom: 26px;
                margin-top: 15px;
                border-bottom: solid rgb(229, 229, 229) 1px;

                .commentInfo {
                  display: flex;
                  width: 100%;

                  .godMsg {
                    position: absolute;
                    right: 55.8px;
                    width: 60.2px;
                    height: 60.2px;
                    z-index: 10;
                  }

                  .avatar {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    margin-right: 12px;
                    flex-shrink: 0;
                  }

                  .info {
                    width: calc(100vw - 100px);
                    flex-shrink: 0;
                    color: rgb(51, 51, 51);

                    .name {
                      color: rgb(51, 51, 51);
                      font-size: 12px;

                      span {
                        margin-right: 4px;
                      }

                      svg {
                        width: 10px;
                        height: 10px;
                      }
                    }

                    .msgContent {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .zanBox {
                        position: absolute;
                        right: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        z-index: 20;

                        .zanIcon {
                          height: 22px;
                          width: 22px;
                          margin-bottom: 5px;
                        }

                        .zanNumber {
                          font-size: 12px;
                        }
                      }
                    }

                    .text {
                      //width: 300px;
                      //text-overflow: ellipsis;
                      //word-wrap: break-word;
                      //white-space: pre-wrap;

                      //width: 100%;
                      //overflow: hidden;
                      white-space: pre-wrap;
                      word-wrap: break-word;
                      text-overflow: ellipsis;

                      font-size: 14px;
                      //margin: 4px 0 7px;
                      margin-top: 5px;
                      color: rgb(51, 51, 51);
                    }

                    .address {
                      //font-size: 10px;
                      //color: rgb(153, 153, 153);
                      margin-top: 8px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .createdAt {
                        font-size: 12px;
                        color: rgb(153, 153, 153);
                      }

                      //.btn {
                      //  font-size: 10px;
                      //  color: rgb(255, 255, 255);
                      //  margin-left: 6px;
                      //}

                      .btnBox {
                        display: flex;
                        align-items: center;

                        .icon {
                          width: 20px;
                          height: 20px;
                        }

                        .community_info {
                          width: 20px;
                          height: 20px;
                        }

                        .like {
                          margin-left: 18px;
                        }
                      }
                    }
                  }

                  .childInfo {
                    width: calc(100vw - 150px);
                    flex-shrink: 0;
                    color: rgb(102, 102, 102);

                    .name {
                      color: rgb(153, 153, 153);
                      font-size: 12px;
                      display: flex;
                      align-items: center;

                      span {
                        margin-right: 4px;
                      }

                      svg {
                        width: 10px;
                        height: 10px;
                      }

                      .rightCommIcon {
                        width: 14px;
                        height: 14px;
                        margin-left: 8px;
                        margin-right: 8px;
                      }
                    }

                    .msgContent {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .zanBox {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        z-index: 20;

                        .zanIcon {
                          height: 22px;
                          width: 22px;
                          margin-bottom: 5px;
                        }

                        .zanNumber {
                          font-size: 12px;
                        }
                      }
                    }

                    .text {
                      //width: 300px;
                      //text-overflow: ellipsis;
                      //word-wrap: break-word;
                      //white-space: pre-wrap;

                      //width: 100%;
                      //overflow: hidden;
                      white-space: pre-wrap;
                      word-wrap: break-word;
                      text-overflow: ellipsis;

                      font-size: 14px;
                      //margin: 4px 0 7px;
                      margin-top: 5px;
                      color: rgb(51, 51, 51);
                    }

                    .address {
                      font-size: 10px;
                      color: rgb(153, 153, 153);
                      margin-top: 8px;

                      .btn {
                        font-size: 10px;
                        color: rgb(255, 255, 255);
                        margin-left: 6px;
                      }
                    }
                  }
                }

                .expand-box {
                  font-size: 12px;
                  color: rgb(153, 153, 153);
                  font-weight: 300;
                  margin: 8px 0;
                  display: flex;
                  align-items: center;

                  > svg {
                    height: 12px;
                    width: 12px;
                    margin-left: 5px;
                  }
                }

                // 旋转180度
                .rotate-target {
                  transform: rotate(180deg);
                  transition: transform 0.3s;
                }

                // 旋转0度
                .rotate-burden-target {
                  transform: rotate(0deg);
                  transition: transform 0.3s;
                }
              }
            }

            // .commentsContentList {
            //   padding: 18px 12px;

            //   .msgItem {
            //     margin-bottom: 26px;

            //     .msgTopBox {
            //       display: flex;
            //       align-items: center;
            //       justify-content: space-between;
            //       margin-bottom: 6px;

            //       .avatar {
            //         width: 34px;
            //         height: 34px;
            //         margin-right: 18px;
            //       }

            //       .infoBox {
            //         flex: 2;
            //         color: rgb(0, 0, 0);

            //         .uName {
            //           font-size: 15px;
            //         }

            //         .dateInfo {
            //           font-size: 12px;
            //         }
            //       }

            //       .likeBox {
            //         display: flex;
            //         flex-direction: column;
            //         align-items: center;
            //         justify-content: center;
            //         color: #a09fb1;
            //         font-size: 12px;

            //         .likeIcon {
            //           width: 16.2px;
            //           height: 14.8px;
            //           margin-bottom: 1.6px;
            //         }
            //       }
            //     }

            //     .msgContent {
            //       margin-left: 52px;
            //       color: #a09fb1;
            //     }

            //     .msgLevel2 {
            //       // width: 100%;
            //       margin: 6px 0 0 52px;
            //       border-radius: 6px;
            //       padding: 9px;
            //       box-sizing: border-box;
            //       background: rgb(230, 230, 230);
            //       font-size: 15px;

            //       .msgBoxLv2 {
            //         display: flex;
            //       }

            //       .nameLv2 {
            //         // flex: 1;
            //         color: rgb(0, 0, 0);
            //         margin-right: 6px;
            //       }

            //       .msgLv2 {
            //         flex: 2;
            //         color: rgb(153, 153, 153);
            //         overflow: hidden;
            //         text-overflow: ellipsis;
            //       }

            //       .msgNum {
            //         font-size: 15px;
            //         color: #a09fb1;
            //         margin-top: 6px;
            //       }
            //     }
            //   }
            // }
          }
        }
      }

      .rerouteBox {
        position: absolute;
        right: 12px;
        padding-top: 12px;
        display: flex;
        align-items: center;
        display: block;
        vertical-align: middle;
      }

      .collectBtn {
        position: absolute;
        right: 12px;
        padding-top: 12px;
        //background: rgb(27,22,76);

        img {
          width: 72px;
          height: 26px;
        }
      }
    }
  }

  .introductionContent {
    padding: 0 12px;

    .introductionBar {
      margin-top: 18px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 14px;
        color: #333333;
        font-weight: 400;
      }

      .btn {
        display: flex;
        align-items: center;

        img {
          width: 14.8px;
          height: 14.8px;
        }
      }
    }

    .introductionTitle {
      font-size: 16px;
      color: rgb(0, 0, 0);
      font-weight: 500;
      margin-top: 16px;
    }

    .introductionTag {
      margin: 8px 0 16px 0;
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;

      span {
        margin-top: 8px;
        margin-right: 12px;
        background: rgba(245, 245, 245, 1);
        border-radius: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 4px 10px;
        line-height: 16.8px;
      }
    }

    .introductionDetails {
      color: rgb(0, 0, 0);
      font-size: 12px;
    }
  }

  .directoryContent {
    .directoryBar {
      padding: 0 12px;
      margin-top: 18px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 18px;
        color: rgb(0, 0, 0);
      }

      .btn {
        display: flex;
        align-items: center;

        img {
          width: 14.8px;
          height: 14.8px;
        }
      }
    }

    .anthologyContent {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;

      .anthologyItem {
        width: 78px;
        height: 42px;
        border-radius: 3px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background: rgb(240, 240, 244);
        margin-left: 12px;
        margin-bottom: 19px;

        .activeItem {
          background: rgba(255, 125, 97, 0.1);
          color: #333333;
          border-radius: 6px;
        }

        .vipLabel {
          display: flex;
          justify-content: flex-end;

          span {
            background-image: linear-gradient(to right, #f3cd64, #fde39c);
            font-size: 10px;
            width: 24px;
            height: 12px;
            color: #663d00;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
          }
        }

        .goldenLabel {
          display: flex;
          justify-content: flex-end;
          width: 78px;

          span {
            .Golden {
              width: 8px;
              height: 8px;
            }
            background-image: linear-gradient(
              to right,
              rgb(255, 57, 87),
              rgb(255, 89, 34)
            );
            font-size: 6px;
            width: 24px;
            height: 12px;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
          }
        }

        .freeLabel {
          height: 12px;
        }

        .activePlay {
          position: relative;
          top: -22px;
          left: 7px;

          img {
            width: 13px;
            height: 8px;
          }
        }

        .anthologyTxt {
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -12px;
        }
      }
    }
  }

  .tabBars {
    background: #ffffff;
    position: fixed;
    right: 0;
    bottom: 0;
    height: 92px;
    width: 100%;
    z-index: 999;
    box-sizing: border-box;

    .comments {
      height: 32px;
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      color: rgb(153, 153, 153);
      font-size: 15px;
      background: #ffffff;

      .Inputs {
        border-radius: 16px;
        background: #eeeeee;

        /deep/ .van-field__control {
          margin-top: -6px !important;
        }
      }

      .sendBtn {
        display: flex;
        align-items: center;

        img {
          margin-left: 12px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .tabBar {
    background: rgb(240, 240, 244);
    position: absolute;
    bottom: 0;
    height: 92px;
    width: 100%;
    z-index: 2;

    .comments {
      height: 59px;
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      color: rgb(153, 153, 153);
      //font-weight: bold;
      font-size: 15px;

      img {
        width: 18.2px;
        height: 18.2px;
      }
    }

    .placeholder {
      height: 34px;
    }
  }

  .inputPop {
    .inputBox {
      height: 59px;
      padding: 0 12px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .newInput {
        width: 273px;
        height: 31px;
        border-radius: 31px;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #e6e6e6;

        /deep/ .van-field__control {
          font-size: 12px;
          color: #000;
        }

        ::placeholder {
          color: #999;
        }
      }

      .sendBtn {
        width: 66px;
        height: 33px;
        border-radius: 33px;
        font-size: 15px;
        color: #fff;
        background: #757494;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .activeBtn {
        color: #fff;
        background: #7145e7;
      }
    }
  }
}

.vipClose {
  color: #ff7f0f;
}

.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  // margin-top: -59px;
  grid-gap: 11px;
  overflow-x: auto;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
.horizontalLine {
  padding: 0 16px;
  box-sizing: border-box;
  height: 1px;
  background: #e6e6e6;
  margin-top: 12px;
}
</style>
