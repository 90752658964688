<template>
  <div>
    <!-- 选择路线弹窗 -->
    <van-popover v-model="isOpen" placement="bottom-end">
      <div class="lineBox">
        <div
          class="line"
          :class="{ selectedLine: item.desc == videoRoadLine.desc }"
          @click.stop="selectLine(item)"
          v-for="item in roadLines"
          :key="item.desc"
        >
          {{ item.desc }}
        </div>
      </div>
      <template #reference>
        <div class="select" @click.stop="isOpen = true">
          <span class="reroute">切换线路</span>
          <img src="@/assets/png/reroute.png" alt="" />
        </div>
      </template>
    </van-popover>
  </div>
</template>
<script>
import { Toast } from "vant";
import { mapGetters } from "vuex";

export default {
  name: "selectionLinePopup",
  data() {
    return {
      roadLines: [],
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      videoRoadLine: "videoRoadLine", // 选择的线路
    }),
  },
  created() {
    let appConfig = this.$store.getters.appConfig;
    let imgDomainList = appConfig?.sourceList?.filter(
      (item) => item.type === "VID"
    );
    if (imgDomainList && imgDomainList.length > 0) {
      this.roadLines = imgDomainList[0]?.domain;
    }
  },
  methods: {
    selectLine(item) {
      this.$store.commit("video/SET_VIDEOROADLINE", item);
      Toast("切换成功");
      this.$emit('isChange');
      this.isOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.lineBox {
  //height: 89px;
  width: 100px;
  border-radius: 10px 0 10px 10px;
  //background: linear-gradient(to bottom, #99d8dc 10%, #ffffff 100%);
  background: linear-gradient(180deg, #FFFCE0 0%, #FFFFFF 100%);
 .line {
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .selectedLine {
    color: #333333;
    // background: rgb(132, 164, 249);
  }
}
.reroute {
  font-size: 12px;
  color: #999999;
  font-weight: 400;
  margin-right: 6px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  /deep/ .van-overlay {
    // z-index: -999 !important;
    background: none !important;
  }
  /deep/ .van-popup--center {
    position: absolute;
    top: 75px;
    // z-index: 999 !important;
  }
  /deep/ .van-popup {
    max-height: 90px !important;
    // overflow-y: none !important;
  }
  /deep/ .lineBox {
    height: 90px;
    width: 100px;
    border-radius: 10px;
    background: linear-gradient(to bottom, #99d8dc 10%, #ffffff 100%);
    .line {
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      color: #333333;
      padding: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
img {
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
}
</style>
